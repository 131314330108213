.ChoiceElement {
  display: block;
  text-align: center;
  cursor: pointer;
}

.ChoiceElement input[type="radio"] {
  appearance: none;
}

.ChoiceElement .card {
  display: block;
  padding: 8px 0 !important;
  margin-top: 10px !important;
  position: relative;
  transition: all 0.2s ease-in;
}
.ChoiceElement input[type="radio"] + .card {
  width: 100%;
  outline: 2px solid rgba(0, 0, 0, 0.15);
  box-shadow: 3px 3px 5px #ccc;
}
.ChoiceElement input[type="radio"]:hover + .card {
  outline: 3px solid #fb8a0a7c;
}
.ChoiceElement input[type="radio"]:checked + .card {
  outline: 5px solid #fb8a0ab5;
}

.ChoiceElement .label {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.8rem;
  font-weight: 500;
}

.ChoiceElement .poster {
  display: block;
  margin: 5px auto;
  max-width: 95%;
  width: auto;
  height: auto;
}

.ChoiceElement .radio {
  height: 2.5rem;
  margin-right: 0.3rem;
}
