.App,
.App input,
.App button {
  /* font-size: 1.2rem !important; */
}
label:not(.form-check-label) {
  font-weight: 500;
}
a {
  color: #61dafb;
  text-decoration: none;
}

.FormError {
  color: red;
  margin-top: 5px;
}

.disclaimer {
  color: #ff5900 !important;
  font-size: 1rem;
  font-weight: 500;
}
